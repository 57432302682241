/* eslint-disable camelcase */
import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { useEffect, useMemo, useState } from "react"
import { ReactComponent as CloseCross } from "../assets/close-cross.svg"

import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import {
  addProductsReset,
  addProductsThunk,
  addProductsTooManyLines,
  getProductsThunk,
} from "../store/ducks/invoicing.duck"
import { useParams } from "react-router-dom"
import { useSelectedCompany } from "../hooks/useSelectedCompany"
import { useRNBSelector } from "../store/rootReducer"
import { toFixed } from "../utils/numbers"
import { Select } from "./Commons/Select"
import { Input } from "./Commons/Input"
import { Button } from "./Commons/Button"
import { Table, TableBuilder } from "./Commons/Table"
import { colors } from "../styles/design.config"
import { TableWrapper } from "./Commons/TableStylesForSelect"
import { Switch } from "./Commons/Switch"
import { Text } from "./Commons/Text"
import { parsePastedContentToInvoices } from "../utils/invoicing"
import { removeIndex } from "../utils/array"

interface ProductProps extends Ct.ModalComponentsProps {}

export interface ProductToCreate {
  product_name: string
  unit: { value: string; label: string }
  vat_rate: { value: string; label: string }
  tax_excluded_price: string
  product_description: string
  only_credit_note: boolean
}

export interface UnitsOption {
  value: string
  label: string
}

export const CreateMultipleProducts = (props: ProductProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const params = useParams()
  const selectedCompany = useSelectedCompany(params, dispatch)

  const { units, vatRatesOptions, addProductsStatus } = useRNBSelector(
    (state) => ({
      units: state.invoicing.units,
      vatRatesOptions: state.vatRates.vatRatesOptions,
      addProductsStatus: state.invoicing.addProductsStatus,
    })
  )

  const initialUnitsOptionsList: Array<UnitsOption> = useMemo(() => {
    return units.map((u) => ({
      value: u.id.toString(),
      label: u.unit,
    }))
  }, [units])

  const unitsOptions: Array<UnitsOption> = initialUnitsOptionsList

  const emptyProduct = {
    product_name: "",
    unit: { value: "", label: "" },
    vat_rate: { value: "", label: "" },
    tax_excluded_price: "0",
    product_description: "",
    only_credit_note: false,
  }

  const [productsToCreate, setProductsToCreate] = useState<ProductToCreate[]>([
    emptyProduct,
  ])

  const [hoveredForDeletionIndex, setHoveredForDeletionIndex] = useState(-1)

  const closeModal = () => {
    dispatch(addProductsReset())
    props.onClose()
  }
  useEffect(() => {
    if (addProductsStatus === "SUCCESS") {
      if (selectedCompany) {
        dispatch(getProductsThunk(selectedCompany.id))
      }
      closeModal()
    }
  }, [addProductsStatus])

  useEffect(() => {
    if (props.isDisplayed === false) {
      setProductsToCreate([emptyProduct])
      setHoveredForDeletionIndex(-1)
    }
  }, [props.isDisplayed])

  const updateProductsAndAddPotentialNewLine = (
    products: ProductToCreate[]
  ) => {
    const isLastLineEmpty =
      JSON.stringify(products[products.length - 1]) ===
      JSON.stringify(emptyProduct)

    if (isLastLineEmpty) {
      return products
    }
    setHoveredForDeletionIndex(-1)
    return [...products, emptyProduct]
  }
  const columns: TableBuilder<ProductToCreate>[] = [
    {
      headerText: "invoicing.products.name",
      width: "32rem",
      flexGrow: "none",
      content: (row: ProductToCreate, index: number) => {
        return (
          <BorderRight>
            <Input
              label=""
              minWidth="30rem"
              maxWidth="32rem"
              height="10rem"
              value={row.product_name}
              borderRadius={0}
              backgroundColor={
                hoveredForDeletionIndex === index
                  ? "rgba(252, 90, 90, 0.2)"
                  : colors.white
              }
              borderColor={"transparent"}
              onChange={(e) => {
                const index = productsToCreate.indexOf(row)
                const updatedProducts = [...productsToCreate]
                updatedProducts[index] = {
                  ...row,
                  product_name: e.target.value,
                }
                setProductsToCreate(
                  updateProductsAndAddPotentialNewLine(updatedProducts)
                )
              }}
            />
          </BorderRight>
        )
      },
    },
    {
      headerText: "invoicing.products.tax-excluded-price",
      flexGrow: "none",
      width: "14rem",
      content: (row: ProductToCreate, index: number) => {
        return (
          <BorderRight>
            <Input
              maxWidth="14rem"
              backgroundColor={
                hoveredForDeletionIndex === index
                  ? "rgba(252, 90, 90, 0.2)"
                  : colors.white
              }
              borderColor={"transparent"}
              height="10rem"
              borderRadius={0}
              onChange={(e) => {
                let price = "0"
                const parsedFloat = parseFloat(e.target.value)
                if (
                  !e.target.value ||
                  isNaN(parsedFloat) ||
                  parseFloat(e.target.value) < 0
                ) {
                  price = "0"
                } else {
                  if (
                    [",", "."].includes(
                      e.target.value[e.target.value.length - 1]
                    )
                  ) {
                    price = parseFloat(e.target.value) + "."
                  } else {
                    if (
                      (parseFloat(e.target.value).toString().split(".")[1]
                        ?.length || 0) > 2
                    ) {
                      price = parseFloat(e.target.value).toFixed(2)
                    } else {
                      price = parseFloat(e.target.value).toString()
                    }
                  }
                }

                const index = productsToCreate.indexOf(row)
                const updatedProducts = [...productsToCreate]
                updatedProducts[index] = {
                  ...row,
                  tax_excluded_price: price,
                }
                setProductsToCreate(
                  updateProductsAndAddPotentialNewLine(updatedProducts)
                )
              }}
              label=""
              value={row.tax_excluded_price?.toString() || ""}
            />
          </BorderRight>
        )
      },
    },
    {
      headerText: "invoicing.products.vat",
      flexGrow: "none",
      width: "14rem",
      content: (row: ProductToCreate, index: number) => {
        return (
          <BorderRight>
            <Select
              intl={intl}
              options={vatRatesOptions}
              value={row.vat_rate}
              defaultValue={null}
              moreSpace={true}
              onChangeCallback={(e: Ct.Option<string>) => {
                const index = productsToCreate.indexOf(row)
                const updatedProducts = [...productsToCreate]
                updatedProducts[index] = {
                  ...row,
                  vat_rate:
                    vatRatesOptions.find((u) => u.value === e.value) ||
                    vatRatesOptions[0],
                }
                setProductsToCreate(
                  updateProductsAndAddPotentialNewLine(updatedProducts)
                )
              }}
              label={""}
              domain={""}
              optionType={""}
              borderRadius={"0"}
              overrideBorderColor={"white"}
              customWidth={"calc(14rem - 1px)"}
              backgroundColor={
                hoveredForDeletionIndex === index
                  ? "rgba(252, 90, 90, 0.2)"
                  : colors.white
              }
              overrideBorderColorStr={"transparent"}
              height="10rem"
            />
          </BorderRight>
        )
      },
    },
    {
      headerText: "invoicing.products.tax-included-price",
      flexGrow: "none",
      width: "10rem",
      content: (row: ProductToCreate, index: number) => {
        return (
          <BorderRight>
            <Cell
              backgroundColor={
                hoveredForDeletionIndex === index
                  ? "rgba(252, 90, 90, 0.2)"
                  : colors.blueGreyBackgroung
              }
              width={"10rem"}
            >
              {Number(
                toFixed(
                  parseFloat(row.tax_excluded_price.replace(",", ".")) *
                    (1 + Number(row.vat_rate.label.replace("%", "")) / 100),
                  2
                )
              )}
            </Cell>
          </BorderRight>
        )
      },
    },
    {
      headerText: "invoicing.products.description",
      content: (row: ProductToCreate, index: number) => {
        return (
          <BorderRight>
            <Input
              label=""
              padding="1.5rem"
              value={row.product_description}
              backgroundColor={
                hoveredForDeletionIndex === index
                  ? "rgba(252, 90, 90, 0.2)"
                  : colors.white
              }
              borderColor={"transparent"}
              borderRadius={0}
              AutoGrowing={true}
              maxWidth={"100%"}
              height={"10rem"}
              maxHeight={"10rem"}
              onTextAreaChange={(e) => {
                const index = productsToCreate.indexOf(row)
                const updatedProducts = [...productsToCreate]
                updatedProducts[index] = {
                  ...row,
                  product_description: e.target.value,
                }
                setProductsToCreate(
                  updateProductsAndAddPotentialNewLine(updatedProducts)
                )
              }}
            />
          </BorderRight>
        )
      },
    },
    {
      headerText: "invoicing.products.unit",
      width: "20rem",
      flexGrow: "none",
      content: (row: ProductToCreate, index: number) => {
        return (
          <BorderRight>
            <Select
              intl={intl}
              options={unitsOptions}
              value={row.unit}
              defaultValue={null}
              onChangeCallback={(e: Ct.Option<string>) => {
                const index = productsToCreate.indexOf(row)
                const updatedProducts = [...productsToCreate]
                updatedProducts[index] = {
                  ...row,
                  unit:
                    unitsOptions.find((u) => u.value === e.value) ||
                    unitsOptions[0],
                }
                setProductsToCreate(
                  updateProductsAndAddPotentialNewLine(updatedProducts)
                )
              }}
              label={""}
              domain={""}
              optionType={""}
              borderRadius={"0"}
              customWidth={"calc(20rem - 1px)"}
              backgroundColor={
                hoveredForDeletionIndex === index
                  ? "rgba(252, 90, 90, 0.2)"
                  : colors.white
              }
              overrideBorderColorStr={"transparent"}
              height="10rem"
            />
          </BorderRight>
        )
      },
    },
    {
      headerText: "invoicing.products.credit-note-only",
      flexGrow: "20rem",
      content: (row: ProductToCreate, index: number) => {
        return (
          <BorderRight>
            <Cell
              backgroundColor={
                hoveredForDeletionIndex === index
                  ? "rgba(252, 90, 90, 0.2)"
                  : colors.white
              }
              width={"20rem"}
            >
              <Switch
                value={row.only_credit_note}
                onToggle={() => {
                  const index = productsToCreate.indexOf(row)
                  const updatedProducts = [...productsToCreate]
                  updatedProducts[index] = {
                    ...row,
                    only_credit_note: !row.only_credit_note,
                  }
                  setProductsToCreate(
                    updateProductsAndAddPotentialNewLine(updatedProducts)
                  )
                }}
              />
            </Cell>
          </BorderRight>
        )
      },
    },
  ]

  return (
    <Ct.Modal
      isDisplayed={props.isDisplayed}
      onClose={() => {
        closeModal()
      }}
      left="50%"
      right="50%"
      top="2vh"
    >
      <StyledCard width={"96vw"} radius={1.25}>
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage({
              id: `invoicing.settings.products.create-multiple-products.title`,
            })}
          />

          <ModalClose onClick={() => closeModal()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <Content>
          <TableWrapper>
            <Table
              intl={intl}
              columns={columns}
              rows={productsToCreate}
              alignItems={"center"}
              width={"100%"}
              maxHeight={"calc(96vh - 50rem)"}
              padding={"0"}
              paddingRows={"0"}
              fontWeightTitle={600}
              sortableColumnsLength={0}
              customScrollBar={true}
              paddingHeader={"0 1rem"}
              deleteConfig={{
                onHover: (rowIndex: number) => {
                  setHoveredForDeletionIndex(rowIndex)
                },
                onDelete: (rowIndex: number) => {
                  setHoveredForDeletionIndex(-1)
                  if (productsToCreate.length === 1) {
                    return
                  }
                  const productsAfterDelete = removeIndex({
                    array: productsToCreate,
                    index: rowIndex,
                  }).slice(0, -1)
                  setProductsToCreate([...productsAfterDelete, emptyProduct])
                },
              }}
            />
          </TableWrapper>
        </Content>

        <Ct.Spacer height={6} />

        <CopySection>
          <Text
            text={intl.formatMessage({
              id: `invoicing.settings.products.create-multiple-products.copy`,
            })}
            textStyle={{
              fontFamily: "Poppins",
              fontWeight: 600,
            }}
          />
          <Ct.Spacer height={0.5} />

          <Input
            label=""
            padding="2px"
            value={""}
            AutoGrowing={true}
            minWidth="100%"
            height={"10rem"}
            maxHeight={"10rem"}
            onTextAreaChange={(e) => {
              const products = parsePastedContentToInvoices(
                e.target.value,
                vatRatesOptions,
                unitsOptions
              )
              const maximumNumberOfProductsToAdd = 1000
              if (products.length > maximumNumberOfProductsToAdd) {
                dispatch(addProductsTooManyLines())
              } else {
                setProductsToCreate(
                  updateProductsAndAddPotentialNewLine(products)
                )
              }
            }}
            placeholder={intl.formatMessage({
              id: `invoicing.settings.products.create-multiple-products.format`,
            })}
          />
        </CopySection>

        <Ct.Spacer height={4} />

        <ButtonsPanel>
          <Button
            label={intl.formatMessage({
              id: `invoicing.settings.products.create-multiple-products.cancel-button`,
            })}
            width={42.5}
            height={6}
            disabled={false}
            colorType="Tertiary"
            colorScheme={{
              border: "mist",
              color: "cornflower",
              background: "mist",
            }}
            onClick={() => {
              props.onClose()
            }}
          />
          <Ct.Spacer />
          <Button
            label={intl.formatMessage({
              id: `invoicing.settings.products.create-multiple-products.validate-button`,
            })}
            width={42.5}
            height={6}
            disabled={productsToCreate.some((p, i) => {
              if (i > 0 && i === productsToCreate.length - 1) {
                const isLastLineEmpty =
                  JSON.stringify(p) === JSON.stringify(emptyProduct)

                if (isLastLineEmpty) {
                  return false
                }
              }

              return (
                !p.product_name ||
                !p.unit.value ||
                !p.vat_rate.value ||
                !p.tax_excluded_price ||
                p.tax_excluded_price === "0"
              )
            })}
            onClick={() => {
              if (!selectedCompany) return

              const productsToCreateFormatted = productsToCreate
                .filter(
                  (p) => JSON.stringify(p) !== JSON.stringify(emptyProduct)
                )
                .map((p) => ({
                  price: p.tax_excluded_price.toString(),
                  productName: p.product_name,
                  productDescription: p.product_description,
                  unitId: p.unit.value,
                  vatRateId: p.vat_rate.value,
                  creditNoteOnly: p.only_credit_note,
                }))
              dispatch(
                addProductsThunk(productsToCreateFormatted, selectedCompany.id)
              )
            }}
          />
        </ButtonsPanel>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`
const Content = styled.div`
  width: 90vw;
`

const CopySection = styled.div`
  width: 90vw;
`

const Cell = styled.div<{ backgroundColor: string; width: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => width};

  height: 10rem;
  user-select: none;
  transition: background-color 0.5s ease-in-out;
`

const BorderRight = styled.div`
  border-right: 1px solid ${colors.mist};
  width: 100%;
`
const ButtonsPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100rem;
`

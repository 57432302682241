import { Account, RulesBillOfExchange } from "../store/ducks/accounts.ducks"
import { Instructions } from "../store/ducks/companySettings.ducks"

export const isValidVatAccount = (
  number: string,
  buyOrSell: "buy" | "sell"
) => {
  const startBuyVat = "4456"

  if (buyOrSell === "buy") {
    return number.startsWith(startBuyVat)
  }

  const startSellVat = "4457"
  return number.startsWith(startSellVat)
}

export const sortAccountInstructionsByDate = (
  allInstructions: Instructions[]
) => {
  return [
    ...allInstructions.sort(
      (a, b) => +new Date(b.created_at) - +new Date(a.created_at)
    ),
  ]
}

export const isValidAutoliquidatedVatAccount = (
  number: string,
  prefix: string[],
  forbid: string[]
) => {
  return (
    prefix.some((pref) => number.startsWith(pref)) &&
    !forbid.some((forbiddenPrefix) => number.startsWith(forbiddenPrefix))
  )
}

export const sortAccountsByNumber = (accounts: Account[], asc: boolean) => {
  return [
    ...accounts.sort((a, b) =>
      asc
        ? a.number?.localeCompare(b.number)
        : b.number?.localeCompare(a.number)
    ),
  ]
}

export const sortAccountsByDetails = (accounts: Account[], asc: boolean) => {
  return [
    ...accounts.sort((a, b) =>
      asc
        ? (a.details || "")?.localeCompare(b.details || "")
        : (b.details || "")?.localeCompare(a.details || "")
    ),
  ]
}

export const sortAccountsDefaultSort = (accounts: Account[]) => {
  const deactivatedAccounts = sortAccountsByNumber(
    accounts.filter((acc) => acc.deactivated_at !== null),
    true
  )
  const activatedAccounts = sortAccountsByNumber(
    accounts.filter((acc) => acc.deactivated_at === null),
    true
  )

  return [...activatedAccounts, ...deactivatedAccounts]
}

export const sortAccountsBySearch = (
  accounts: Account[],
  search: string,
  filterPrefixOnly: boolean
) => {
  return filterPrefixOnly
    ? [
        ...accounts.filter(
          (acc) =>
            acc.number.toLowerCase().slice(0, search.length) ===
            search.toLowerCase()
        ),
      ]
    : [
        ...accounts.filter(
          (acc) =>
            acc.number.toLowerCase().includes(search.toLowerCase()) ||
            acc.details?.toLowerCase().includes(search.toLowerCase())
        ),
      ]
}

export const sortRulesByPriority = (
  rules: RulesBillOfExchange[],
  asc: boolean
) => {
  return rules.sort((a, b) => {
    return asc
      ? a.priority_number - b.priority_number
      : b.priority_number - a.priority_number
  })
}

export const searchRulesBillOfExchange = (
  rules: RulesBillOfExchange[],
  search: string
) => {
  if (rules.length === 0) return []
  if (search.length === 0) return sortRulesByPriority(rules, true)

  return rules.filter((r) => {
    return (
      r.text_in_description.toLowerCase().includes(search) ||
      r.code.toLowerCase().includes(search) ||
      search.includes(r.text_in_description.toLowerCase()) ||
      search.includes(r.code.toLowerCase())
    )
  })
}

export interface AccountToCreate {
  number: string
  details: string
}

export const parsePastedContentToAccounts = (
  pastedContent: string
): AccountToCreate[] => {
  const separators = ["\t", ";"]

  const pastedAccounts: AccountToCreate[] = []

  pastedContent
    .replace(/\r/g, "")
    .split("\n")
    .forEach((line) => {
      const separator =
        separators.find((s) => line.includes(s)) || separators[0]
      const values = line.split(separator)

      if (values.length >= 1 && values.length <= 2) {
        const accountNumber = values[0]
        if (accountNumber !== "") {
          pastedAccounts.push({
            number: accountNumber.trim(),
            details: values[1].trim() || "",
          })
        }
      }
    })

  return pastedAccounts
}

import { CSSProperties, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components/macro"
import {
  GetAllMerchantsOfCompanyForCodes,
  GetCompanyMerchantCodesDataThunk,
  MerchantCode,
  updateCentralizeStatus,
} from "../../../../store/ducks/merchantCodes.ducks"
import { useRNBSelector } from "../../../../store/rootReducer"
import { boxShadow, colors } from "../../../../styles/design.config"
import { getIdFromParams } from "../../../../utils/company"
import * as Ct from "ldlj"
import { ReactComponent as Search } from "../../../../assets/search.svg"
import { ReactComponent as Eye } from "../../../../assets/eyeFilled.svg"
import { ReactComponent as Plus } from "../../../../assets/plus.svg"
import { TableWrapper } from "../../../../components/Commons/TableStylesForSelect"
import AutoSizer from "react-virtualized-auto-sizer"
import {
  GridWrapper,
  HeaderTitleWrapper,
  HeaderWrapper,
  LoaderWrapper,
} from "../../../../components/dropDocuments/StyledDropsComponents"
import { Alert } from "../../../../components/Commons/Alert"
import { Text } from "../../../../components/Commons/Text"
import { Switch } from "../../../../components/Commons/Switch"
import { VariableSizeGrid } from "react-window"
import { useIntl } from "react-intl"
import React from "react"
import {
  filterMerchantCodes,
  sortMerchantCodeMerchants,
  sortMerchantCodes,
  sortMerchantCodesByTiers,
  sortMerchantCodesByCode,
} from "../../../../utils/merchantCodes"
import { ReactComponent as Edit } from "../../../../assets/edit.svg"
import { ReactComponent as UniqCode } from "../../../../assets/uniqCode.svg"
import { EditMerchantCode } from "../../../../components/accountingPlan/EditMerchantCode"
import { CodeHistoryModal } from "../../../../components/accountingPlan/CodeHistoryModal"
import { DecentralizeCodeModal } from "../../../../components/accountingPlan/DecentralizeCodeModal"
import { AddMerchantsToCodeModal } from "../../../../components/accountingPlan/AddMerchantsToCodeModal"
import ReactTooltip from "react-tooltip"
import {
  TitleTable,
  ClickableTitleSort,
  SortToReturn,
  ChevronProps,
} from "../../../../components/Commons/Table"
import { updateDisabledCentralizedCodesThunk } from "../../../../store/ducks/companies.ducks"
import { CreateMerchantCodesModal } from "../../../../components/accountingPlan/CreateMerchantCodesModal"

export const AccountingPlanCodes = () => {
  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0
  const dispatch = useDispatch()
  const intl = useIntl()
  const navigate = useNavigate()

  const [search, setSearch] = useState<string>("")
  const listRef = React.createRef<VariableSizeGrid>()
  const [listWidth, setListWidth] = useState(0)
  const [listHeight, setListHeight] = useState(0)
  const [sortedMerchantCodes, setSortedMerchantCodes] = useState<
    MerchantCode[]
  >([])
  const [toggleName, setToggleName] = useState<{
    open: boolean
    merchantCodeId: number | null
  }>({ open: false, merchantCodeId: null })
  const [rowHeigths, setRowHeights] = useState<number[]>([])
  const [displayRenameCode, setDisplayRenameCode] =
    useState<MerchantCode | null>(null)
  const [displayHistory, setDisplayHistory] = useState<MerchantCode | null>(
    null
  )
  const [displayDecentralize, setDisplayDecentralize] =
    useState<MerchantCode | null>(null)
  const [displayAddMerchants, setDisplayAddMerchants] =
    useState<MerchantCode | null>(null)
  const [displayCreateCode, setDisplayCreateCode] = useState<boolean>(false)
  const [disableCentralizedCodes, setDisableCentralizedCodes] =
    useState<boolean>(false)

  const merchantCodes = useRNBSelector(
    (state) => state.merchantCodes.merchantCodes
  )
  const userTypology = useRNBSelector((state) => state.user.typology)
  if (userTypology === "customer") navigate("/Unauthorized")
  const userInformations = useRNBSelector((state) => state.user)
  const fiduciaryInformations = useRNBSelector((state) => state.fiduciary)
  const company = useRNBSelector(
    (state) => state.companies.companies[selectedCompanyId]
  )
  const user = useRNBSelector((state) => state.user)

  useEffect(() => {
    if (company && company.disable_centralized_codes !== undefined) {
      setDisableCentralizedCodes(company.disable_centralized_codes)
    }
  }, [company])

  useEffect(() => {
    setSearch("")
    setToggleName({ open: false, merchantCodeId: null })
    dispatch(GetCompanyMerchantCodesDataThunk(selectedCompanyId))
    dispatch(GetAllMerchantsOfCompanyForCodes(selectedCompanyId))
  }, [selectedCompanyId, dispatch])

  useEffect(() => {
    if (merchantCodes) {
      setSortedMerchantCodes(sortMerchantCodes(merchantCodes))
    }
  }, [merchantCodes])

  useEffect(() => {
    setToggleName({ open: false, merchantCodeId: null })
    const rowHeights = new Array(sortedMerchantCodes.length)
      .fill(true)
      .map(() => 60)
    setRowHeights(rowHeights)
    onResize()
  }, [sortedMerchantCodes])

  useEffect(() => {
    if (merchantCodes) {
      setSortedMerchantCodes(
        sortMerchantCodes(
          Object.values(merchantCodes).filter(filterMerchantCodes(search))
        )
      )
    }
  }, [search, merchantCodes])

  /* eslint-disable camelcase */
  useEffect(() => {
    if (userInformations && company) {
      window.userpilot.identify(userInformations.id, {
        name: userInformations.firstName + " " + userInformations.lastName,
        email: userInformations.email,
        first_name: userInformations.firstName,
        last_name: userInformations.lastName,
        created_at: userInformations.created_at,
        role: userInformations.typology,
        company: {
          id: fiduciaryInformations.id, // Required, used to identify the company
          name: fiduciaryInformations.name,
          created_at: fiduciaryInformations.created_at,
          company_country: "FR",
          company_accounting_type: company.accounting_type,
        },
      })
    }
  }, [
    userInformations,
    fiduciaryInformations,
    userInformations.typology,
    company,
  ])

  // eslint-disable-next-line
  const onResize = (...args: any) => {
    if (listRef.current != null) {
      listRef.current.resetAfterRowIndex(0, true)
    }
  }

  const toggleHandler = (merchantCode: MerchantCode, more: boolean) => {
    if (more) {
      const indexMerchant = sortedMerchantCodes.indexOf(merchantCode)

      const newRows = new Array(sortedMerchantCodes.length)
        .fill(true)
        .map((_, index) => {
          return indexMerchant === index ? 60 + 60 : 60
        })

      setToggleName({ open: true, merchantCodeId: merchantCode.id })
      setRowHeights(newRows)
      onResize()
    } else {
      const newRows = new Array(sortedMerchantCodes.length)
        .fill(true)
        .map((_) => 60)

      setToggleName({ open: false, merchantCodeId: null })
      setRowHeights(newRows)
      onResize()
    }
  }

  const columns: Ct.TableBuilder<MerchantCode>[] = [
    {
      headerText: "accounting-plan.table-header.code",
      content: (merchantCode: MerchantCode) => {
        return (
          <WrapperCode>
            <div>
              <FlexBetween>
                <Ct.Row>
                  <Text
                    text={merchantCode.code}
                    textStyle={{
                      fontSize: 2,
                      fontWeight: 600,
                      color: "navy",
                    }}
                  />
                </Ct.Row>
                <Ct.Row>
                  <StyledEdit
                    onClick={() => {
                      setDisplayRenameCode(merchantCode)
                    }}
                  />
                  <Ct.Spacer width={3} />
                </Ct.Row>
              </FlexBetween>
            </div>
            {(merchantCode.default_buy_code ||
              merchantCode.default_sell_code) && (
              <>
                <Ct.Spacer height={0.5} width={0} />
                <Ct.Row>
                  <StyledUniqCode />
                  <Ct.Spacer width={0.5} height={0} />
                  <Text
                    text={intl.formatMessage(
                      { id: "accounting-plan.default_buy_or_sell_code" },
                      {
                        buyOrSell:
                          merchantCode.default_buy_code &&
                          merchantCode.default_sell_code
                            ? "achats et ventes"
                            : merchantCode.default_buy_code
                            ? "achats"
                            : "ventes",
                      }
                    )}
                    textStyle={{
                      fontSize: 1.4,
                      color: "cornflower",
                    }}
                  />
                </Ct.Row>
              </>
            )}
          </WrapperCode>
        )
      },
    },
    {
      headerText: "accounting-plan.table-header.clients",
      content: (merchantCode: MerchantCode) => {
        let merchantNames: string = ""

        if (merchantCode.merchants && merchantCode.merchants?.length > 0) {
          merchantNames = sortMerchantCodeMerchants(
            merchantCode.merchants
          ).join(", ")
        }

        return (
          <FlexBetween>
            {merchantNames.length > 0 && (
              <StyledP
                moreOrLess={
                  toggleName.open &&
                  toggleName.merchantCodeId === merchantCode.id
                }
              >
                <Text
                  text={
                    toggleName.open &&
                    toggleName.merchantCodeId === merchantCode.id
                      ? merchantNames
                      : merchantNames.slice(0, listWidth < 1050 ? 90 : 130)
                  }
                  textStyle={{
                    fontSize: 2,
                    color: "navy",
                  }}
                />

                {merchantNames.length > (listWidth < 1050 ? 90 : 130) && (
                  <span>
                    <SeeMore
                      text={intl.formatMessage(
                        { id: "accounting-plan.table-content.see-more" },
                        {
                          moreOrLess:
                            toggleName.open &&
                            toggleName.merchantCodeId === merchantCode.id
                              ? "moins"
                              : "plus",
                        }
                      )}
                      textStyle={{
                        fontSize: 2,
                        color: "cornflower",
                      }}
                      onClick={() => {
                        toggleHandler(merchantCode, !toggleName.open)
                      }}
                    />
                  </span>
                )}
              </StyledP>
            )}
            <Ct.Spacer height={0} width={2} />

            <Ct.Button
              label={"Ajouter"}
              width={11}
              disabled={
                (merchantCode.merchants &&
                  merchantCode.merchants?.length >= 1 &&
                  !merchantCode.centralize) ||
                false
              }
              onClick={() => {
                setDisplayAddMerchants(merchantCode)
              }}
            />
          </FlexBetween>
        )
      },
    },
    {
      headerText: "accounting-plan.table-header.centralize",
      content: (merchantCode: MerchantCode) => {
        return (
          <SwitchWrapper
            data-tip={
              merchantCode.default_buy_code || merchantCode.default_sell_code
                ? intl.formatMessage(
                    {
                      id: "accounting-plan.tooltip.default-no-decentralize",
                    },
                    {
                      buyOrSell:
                        merchantCode.default_buy_code &&
                        merchantCode.default_sell_code
                          ? "achats et ventes"
                          : merchantCode.default_buy_code
                          ? "achats"
                          : "ventes",
                    }
                  )
                : disableCentralizedCodes
                ? intl.formatMessage({
                    id: "accounting-plan.tooltip.disabled-centralize-code",
                  })
                : ""
            }
          >
            <ReactTooltip
              delayShow={300}
              effect={"solid"}
              eventOff="mouseleave scroll mousewheel blur"
            />

            <Switch
              value={merchantCode.centralize}
              disabled={
                merchantCode.default_buy_code ||
                merchantCode.default_sell_code ||
                disableCentralizedCodes
              }
              onToggle={() => {
                if (
                  merchantCode.default_buy_code ||
                  merchantCode.default_sell_code ||
                  disableCentralizedCodes
                ) {
                  return
                }
                if (
                  merchantCode.centralize &&
                  merchantCode.merchants &&
                  merchantCode.merchants?.length > 1
                ) {
                  setDisplayDecentralize(merchantCode)
                } else {
                  dispatch(
                    updateCentralizeStatus(
                      merchantCode.id,
                      !merchantCode.centralize,
                      selectedCompanyId
                    )
                  )
                }
              }}
            />
            <Ct.Spacer width={2} />
          </SwitchWrapper>
        )
      },
    },
    {
      headerText: "accounting-plan.table-header.historical",
      content: (merchantCode: MerchantCode) => (
        <LastItem>
          <div
            onClick={() => {
              setDisplayHistory(merchantCode)
            }}
          >
            <StyledEye />
          </div>
        </LastItem>
      ),
    },
  ]

  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: CSSProperties | undefined
  }) => {
    const merchantCode: MerchantCode = sortedMerchantCodes[rowIndex]

    return (
      <>
        <div
          style={{
            ...style,
            backgroundColor: rowIndex % 2 === 0 ? "white" : "#F4F8FF",
          }}
          key={columnIndex}
        >
          {rowIndex > 0 && <Ct.Separator size="full" color={"lavender"} />}
          <StyledCell
            style={{ height: "100%", width: "100%" }}
            alignItems={columnIndex === 0 ? "flex-start" : "center"}
          >
            <Ct.Row
              style={{
                width: "100%",
                justifyContent: columnIndex === 0 ? "flex-start" : "center",
                height: "100%",
              }}
            >
              {columnIndex === 0 && <Ct.Spacer width={4} />}
              {columnIndex === columns.length && <Ct.Spacer width={4} />}
              {columns[columnIndex].content(merchantCode)}
            </Ct.Row>
          </StyledCell>
        </div>
      </>
    )
  }

  enum SortOptionsValues {
    "code",
    "tiers",
  }

  const sorter = (asc: boolean) => (option: SortOptionsValues) => {
    if (option === SortOptionsValues?.tiers) {
      setSortedMerchantCodes(sortMerchantCodesByTiers(sortedMerchantCodes, asc))
    } else if (option === SortOptionsValues?.code) {
      setSortedMerchantCodes(sortMerchantCodesByCode(sortedMerchantCodes, asc))
    }
  }

  const [columnToSort, setColumnToSort] = useState<SortToReturn | null>(null)
  const [currentChevron, setCurrentChevron] = useState<ChevronProps>({
    direction: "none",
    index: 0,
  })

  useEffect(() => {
    if (columnToSort) {
      const currentSort: SortOptionsValues = Object.values(
        SortOptionsValues
      ).indexOf(SortOptionsValues[columnToSort.index])
      if (columnToSort.direction === "up") {
        setCurrentChevron({ index: columnToSort.index, direction: "up" })
        sorter(columnToSort.asc)(currentSort)
      } else if (columnToSort.direction === "down") {
        setCurrentChevron({ index: columnToSort.index, direction: "down" })
        sorter(columnToSort.asc)(currentSort)
      } else {
        setCurrentChevron({ index: columnToSort.index, direction: "none" })
        setSortedMerchantCodes(
          sortMerchantCodes(
            Object.values(merchantCodes).filter(filterMerchantCodes(search))
          )
        )
      }
    }
  }, [columnToSort])

  return (
    <Wrapper>
      <StyledSection>
        <Header>
          <StyledInput
            name={"search"}
            id={"search"}
            label="Rechercher"
            value={search}
            suffix={<Search />}
            maxWidth={30}
            onChange={(event: { target: HTMLInputElement }) => {
              const value = event.target.value.trimStart().replace("  ", " ")
              setSearch(value.replace(/[^a-zA-Z0-9 .-]/g, ""))
            }}
            shadowed={true}
            noBorder={true}
          />

          <WrapperRight>
            <ReactTooltip
              delayShow={300}
              effect={"solid"}
              eventOff="mouseleave scroll mousewheel blur"
            />
            <SwitchWrapper>
              <span
                data-tip={
                  merchantCodes.some((c) => c.centralize)
                    ? intl.formatMessage({
                        id: "accounting-plan.tooltip.switch-disabled-centralized-code-tooltip",
                      })
                    : ""
                }
              >
                <Switch
                  value={disableCentralizedCodes}
                  onToggle={() => {
                    dispatch(
                      updateDisabledCentralizedCodesThunk(
                        company.id,
                        !disableCentralizedCodes,
                        user.id
                      )
                    )
                  }}
                  disabled={merchantCodes.some((c) => c.centralize)}
                />
              </span>

              <Ct.Spacer width={2} />
              <Ct.Text
                text={intl.formatMessage({
                  id: "accounting-plan.switch-no-centralized-code",
                })}
                textStyle={{
                  fontSize: 1.75,
                  color: "navy",
                }}
              />
            </SwitchWrapper>

            <Ct.Spacer width={3} />

            <Ct.Button
              label={intl.formatMessage({
                id: "accounting-plan.open-create-client",
              })}
              width={35}
              prefix={<StyledPlus />}
              onClick={() => {
                setDisplayCreateCode(true)
              }}
            />
          </WrapperRight>
        </Header>

        <StyledTable>
          <AutoSizer onResize={onResize}>
            {({ height, width }: { height: number; width: number }) => {
              const columnWidths = columns.map((_, index) => {
                if (index === 1) {
                  return 500
                }
                if (index === 2 && width < 1050) {
                  return 100
                }
                if (index === 3) {
                  return width < 1050 ? 100 : 150
                }

                // split remaining space evenly for other columns
                return width < 1050
                  ? (width - (80 + 625) - 10) / (columns.length - 3)
                  : (width - (80 + 575) - 10) / (columns.length - 2)
              })

              if (
                listRef?.current &&
                (width !== listWidth || height !== listHeight)
              ) {
                listRef.current.resetAfterColumnIndex(0, true)
              }
              setListWidth(width)
              setListHeight(height)

              return (
                <>
                  <HeaderWrapper totalWidth={width} paddingHeader={"0 1rem"}>
                    {columns.map((column, index) => (
                      <HeaderTitleWrapper
                        calculatedWidth={columnWidths[index]}
                        key={column.headerText}
                      >
                        {index < columns.length - 2 ? (
                          <ClickableTitleSort
                            tid={column.headerText}
                            intl={intl}
                            index={index}
                            sortToReturn={(column: SortToReturn) => {
                              setColumnToSort(column)
                            }}
                            currentChevron={currentChevron}
                          />
                        ) : (
                          <TitleTable tid={column.headerText} intl={intl} />
                        )}
                      </HeaderTitleWrapper>
                    ))}
                  </HeaderWrapper>

                  {sortedMerchantCodes.length === 0 ? (
                    <LoaderWrapper totalWidth={width} height={height - 56}>
                      <Alert alertType="info">
                        <Text
                          text={intl.formatMessage({
                            id:
                              Object.values(merchantCodes).length > 0
                                ? "accounting-plan.table-content.empty-search"
                                : "accounting-plan.table-content.empty",
                          })}
                        />
                      </Alert>
                    </LoaderWrapper>
                  ) : (
                    <>
                      <GridWrapper totalWidth={width}>
                        <VariableSizeGrid
                          ref={listRef}
                          height={height - 56} // header size
                          rowCount={sortedMerchantCodes.length}
                          width={width} //scrollbar
                          columnWidth={(index) => columnWidths[index]}
                          rowHeight={(index) => rowHeigths[index]}
                          columnCount={columns.length}
                        >
                          {Cell}
                        </VariableSizeGrid>
                      </GridWrapper>
                    </>
                  )}
                </>
              )
            }}
          </AutoSizer>
        </StyledTable>

        {displayRenameCode && (
          <EditMerchantCode
            isDisplayed={!!displayRenameCode}
            merchantCode={displayRenameCode}
            onClose={() => {
              setDisplayRenameCode(null)
            }}
          />
        )}

        {displayHistory && (
          <CodeHistoryModal
            merchantCode={displayHistory}
            isDisplayed={!!displayHistory}
            onClose={() => {
              setDisplayHistory(null)
            }}
          />
        )}

        {displayDecentralize &&
          displayDecentralize.centralize &&
          displayDecentralize.merchants &&
          displayDecentralize.merchants?.length > 1 && (
            <DecentralizeCodeModal
              merchantCode={displayDecentralize}
              isDisplayed={!!displayDecentralize}
              onClose={() => {
                setDisplayDecentralize(null)
              }}
              merchants={displayDecentralize.merchants}
            />
          )}

        {displayAddMerchants && (
          <AddMerchantsToCodeModal
            merchantCode={displayAddMerchants}
            isDisplayed={!!displayAddMerchants}
            onClose={() => {
              setDisplayAddMerchants(null)
            }}
          />
        )}

        {displayCreateCode && (
          <CreateMerchantCodesModal
            isDisplayed={displayCreateCode}
            onClose={() => setDisplayCreateCode(false)}
            companyId={selectedCompanyId}
          />
        )}
      </StyledSection>
    </Wrapper>
  )
}

/* stylelint-disable no-descending-specificity */

const Wrapper = styled.div`
  padding-bottom: 4rem;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`
const StyledSection = styled.section`
  border-radius: 0 2.5rem 2.5rem;
  background-color: ${colors.white};
  box-shadow: ${boxShadow};
  padding: 0 2rem 4rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4.75rem 3rem 4.5rem 3rem;
`

const StyledInput = styled((props) => <Ct.Input {...props} />)`
  box-shadow: ${boxShadow};
`
interface StyledCellProps {
  width: string
  alignItems: string
}
const WrapperCode = styled.div`
  margin: auto 0;
  width: 100%;
`
const StyledCell = styled(Ct.ColumnCenterCenter)<StyledCellProps>`
  width: ${(props) => props.width};
  align-items: ${(props) => props.alignItems};
`
const StyledEdit = styled(({ ...props }) => <Edit {...props} />)`
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.lavender};
    }
  }
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }

  width: 3rem;
  height: 3rem;
  cursor: pointer;
`
const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledEye = styled(({ ...props }) => <Eye {...props} />)`
  & path {
    transition: all 0.2s ease-in-out;
    fill: ${colors.cornflower};
  }
  :hover {
    & path {
      transition: all 0.2s ease-in-out;
      fill: ${colors.lavender};
    }
  }
  cursor: pointer;
`
const LastItem = styled((props) => <Ct.Cell {...props} />)`
  display: flex;
  justify-content: space-around;
  height: 100%;
`
const SeeMore = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
  padding-left: 1rem;

  :hover {
    color: ${colors.cornflower};
  }
`
const StyledTable = styled(TableWrapper)`
  border: none;
`
interface StyledPProps {
  moreOrLess: boolean
}
const StyledP = styled.p<StyledPProps>`
  overflow-y: auto;
  height: ${(props) => (props.moreOrLess ? "100%" : "fit-content")};
`
const WrapperRight = styled.div`
  display: flex;
`
const StyledPlus = styled(Plus)`
  & path {
    fill: white;
  }
`
const StyledUniqCode = styled(({ ...props }) => <UniqCode {...props} />)`
  & path {
    fill: ${colors.cornflower};
  }
`

import * as Ct from "ldlj"
import styled from "styled-components/macro"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"

import { ReactComponent as CloseCross } from "../assets/close-cross.svg"
import { ReactComponent as Warning } from "../assets/warning.svg"
import { colors } from "../styles/design.config"

import { getIdFromParams } from "../utils/company"
import { removeIndex } from "../utils/array"
import {
  AccountToCreate,
  parsePastedContentToAccounts,
} from "../utils/accounts"

import { useRNBSelector } from "../store/rootReducer"
import {
  addAccountsTooManyLines,
  createAccountResetAction,
  createAccountThunk,
  createMultipleAccountThunk,
  getAllAccountsThunk,
} from "../store/ducks/accounts.ducks"

import { TableWrapper } from "./Commons/TableStylesForSelect"
import { Table, TableBuilder } from "./Commons/Table"
import { Input } from "./Commons/Input"
import { Alert } from "./Commons/Alert"
import { Text } from "./Commons/Text"

interface CreateAccountModalProps {
  onClose: () => void
  isDisplayed: boolean
  writingLineUuid?: string
  multiple?: boolean
}

export const CreateAccountsModal = ({
  isDisplayed,
  onClose,
  multiple = false,
  writingLineUuid,
}: CreateAccountModalProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const selectedCompanyId = getIdFromParams(useParams())("company_id")
  const emptyAccount: AccountToCreate = {
    number: "",
    details: "",
  }

  const accounts = useRNBSelector((state) => state.accounts.all_accounts)
  const accountCreateStatus = useRNBSelector(
    (state) => state.accounts.accountCreateStatus
  )

  const [accountsSet, setAccountsSet] = useState<Set<string>>(new Set())
  const [hoveredForDeletionIndex, setHoveredForDeletionIndex] = useState(-1)
  const [accountsToCreate, setAccountsToCreate] = useState<AccountToCreate[]>([
    emptyAccount,
  ])
  const [hasAccountAlreadyCreated, setHasAccountAlreadyCreated] =
    useState(false)
  const [hasDuplicates, setHasDuplicates] = useState(false)

  const accountHasDuplicates = (number: string) => {
    if (number === "") return false

    let count = 0
    for (const item of accountsToCreate) {
      if (item.number === number) {
        count++
        if (count > 1) return true
      }
    }

    return false
  }

  const getBackgroundColor = (
    index: number,
    number: string,
    details: string
  ): string => {
    if (index === hoveredForDeletionIndex) return "rgba(252, 90, 90, 0.2)"
    else if (accountsSet.has(number)) return "rgba(252, 90, 90, 0.3)"
    else if (
      accountHasDuplicates(number) ||
      (number !== details && number === "")
    )
      return "rgba(252, 150, 90, 0.4)"
    else return colors.white
  }

  const getBackgroundColorDetails = (
    index: number,
    number: string,
    details: string
  ): string => {
    if (index === hoveredForDeletionIndex) return "rgba(252, 90, 90, 0.2)"
    else if (number !== details && details.trim() === "")
      return "rgba(252, 150, 90, 0.4)"
    else return colors.white
  }

  const updateAccountsAndAddPotentialNewLine = (
    accounts: AccountToCreate[]
  ) => {
    const isLastLineEmpty =
      JSON.stringify(accounts[accounts.length - 1]) ===
      JSON.stringify(emptyAccount)

    if (isLastLineEmpty || !multiple) {
      return accounts
    }
    setHoveredForDeletionIndex(-1)
    return [...accounts, emptyAccount]
  }

  const disableSaveButton = () => {
    const atc = accountsToCreate.filter(
      (atc) => atc.number.trim() !== "" && atc.details.trim() !== ""
    )
    const atc2 = accountsToCreate.filter(
      (atc) => atc.number.trim() !== "" || atc.details.trim() !== ""
    )
    if (multiple) {
      return (
        atc.length === 0 ||
        atc.length !== atc2.length ||
        hasDuplicates ||
        hasAccountAlreadyCreated
      )
    } else {
      return (
        !atc[0] ||
        atc[0].number === "" ||
        atc[0].details === "" ||
        accountsSet.has(atc[0].number)
      )
    }
  }

  const handleFormSubmit = () => {
    const atc = accountsToCreate.filter(
      (atc) => atc.number !== "" && atc.details !== ""
    )
    if (selectedCompanyId) {
      if (multiple) {
        dispatch(createMultipleAccountThunk(selectedCompanyId, atc))
      } else {
        dispatch(
          createAccountThunk(
            selectedCompanyId,
            atc[0].number,
            atc[0].details,
            writingLineUuid
          )
        )
      }
    }
  }

  const resetForm = () => {
    dispatch(createAccountResetAction())
    setHoveredForDeletionIndex(-1)
    setAccountsToCreate([emptyAccount])
    setHasAccountAlreadyCreated(false)
    setHasDuplicates(false)
  }

  const closeModal = () => {
    resetForm()
    onClose()
  }

  const rowHeightInRem = 8
  const columns: TableBuilder<AccountToCreate>[] = [
    {
      headerText: "accounting-plan.accounts.modal.input.number",
      width: "30rem",
      flexGrow: "none",
      content: (row: AccountToCreate, index: number) => {
        return (
          <BorderRight>
            <Input
              label=""
              minWidth="28rem"
              maxWidth="30rem"
              height={rowHeightInRem + "rem"}
              value={row.number}
              borderRadius={0}
              autoFocus={index === 0}
              placeholder={
                index === accountsToCreate.length - 1 && multiple
                  ? intl.formatMessage({
                      id: "accounting-plan.accounts.modal.paste.format",
                    })
                  : ``
              }
              placeholderColor={multiple ? colors.rock : "transparent"}
              backgroundColor={getBackgroundColor(
                index,
                row.number,
                row.details
              )}
              borderColor={"transparent"}
              onPaste={(e) => {
                if (multiple) {
                  const pastedData = e.clipboardData.getData("Text")
                  if (
                    pastedData.length > 1 &&
                    (pastedData.includes(";") || pastedData.includes("\t"))
                  ) {
                    e.preventDefault()
                    const pastedAcounts =
                      parsePastedContentToAccounts(pastedData)
                    const maximumNumberOfAccountsToAdd = 1000
                    if (pastedAcounts.length > maximumNumberOfAccountsToAdd) {
                      dispatch(addAccountsTooManyLines())
                    } else {
                      setAccountsToCreate(
                        updateAccountsAndAddPotentialNewLine([
                          ...accountsToCreate.filter(
                            (act) => act.number !== ""
                          ),
                          ...pastedAcounts,
                        ])
                      )
                    }
                  }
                }
              }}
              onChange={(e) => {
                const index = accountsToCreate.indexOf(row)
                const updatedAccounts = [...accountsToCreate]

                const value =
                  isNaN(Number(e.target.value)) ||
                  [".", " "].includes(
                    e.target.value.charAt(e.target.value.length - 1)
                  )
                    ? accountsToCreate[index].number
                    : e.target.value.trim()

                updatedAccounts[index] = {
                  ...row,
                  number: value,
                }
                setAccountsToCreate(
                  updateAccountsAndAddPotentialNewLine(updatedAccounts)
                )
              }}
            />
          </BorderRight>
        )
      },
    },
    {
      headerText: "accounting-plan.accounts.modal.input.details",
      width: "80rem",
      flexGrow: "",
      content: (row: AccountToCreate, index: number) => {
        return (
          <BorderRight>
            <Input
              label=""
              minWidth="48rem"
              maxWidth="90rem"
              height={rowHeightInRem + "rem"}
              value={row.details}
              borderRadius={0}
              backgroundColor={getBackgroundColorDetails(
                index,
                row.number,
                row.details
              )}
              borderColor={"transparent"}
              onChange={(e) => {
                const index = accountsToCreate.indexOf(row)
                const updatedAccounts = [...accountsToCreate]
                updatedAccounts[index] = {
                  ...row,
                  details: e.target.value,
                }
                setAccountsToCreate(
                  updateAccountsAndAddPotentialNewLine(updatedAccounts)
                )
              }}
            />
          </BorderRight>
        )
      },
    },
  ]

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(getAllAccountsThunk(selectedCompanyId))
    }
  }, [])

  useEffect(() => {
    const acSet = new Set(accounts.map((acc) => acc.number))
    setAccountsSet(acSet)
  }, [accounts])

  useEffect(() => {
    if (accountCreateStatus === "SUCCESS") {
      createAccountResetAction()
      closeModal()
    }
  }, [accountCreateStatus])

  useEffect(() => {
    const actNumbers = accountsToCreate
      .filter((atc) => atc.number.trim() !== "")
      .map((e) => e.number)
    setHasAccountAlreadyCreated(
      [...accountsSet].some((a) => new Set(actNumbers).has(a))
    )
    setHasDuplicates(new Set(actNumbers).size !== actNumbers.length)
  }, [accountsToCreate, accountsSet])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={closeModal}
      left="50%"
      right="50%"
      top={`calc(30vh - 30rem)`}
    >
      <StyledCard width={"150rem"}>
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage({
              id: `accounting-plan.accounts.modal.title.create${
                multiple ? ".multiple" : ".single"
              }`,
            })}
          />

          <ModalClose onClick={() => closeModal()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        {multiple && (
          <>
            <Information>
              <StyledInfoTitle
                text={intl.formatMessage({
                  id: "accounting-plan.accounts.modal.info.title",
                })}
              />
              <Ct.Spacer height={1} />
              <Alert alertType={"bulb"}>
                <Text
                  text={intl.formatMessage({
                    id: "accounting-plan.accounts.modal.info.content",
                  })}
                />
              </Alert>
            </Information>
            <Ct.Spacer height={3} />
          </>
        )}

        <Content>
          <TableWrapper>
            <Table
              intl={intl}
              columns={columns}
              rows={accountsToCreate}
              alignItems={"center"}
              width={"100%"}
              maxHeight={"50rem"}
              padding={"0"}
              paddingRows={"0"}
              fontWeightTitle={600}
              sortableColumnsLength={0}
              customScrollBar={true}
              paddingHeader={"0 1rem"}
              healerFirstColumnLeft={false}
              deleteConfig={
                multiple
                  ? {
                      onHover: (rowIndex: number) => {
                        setHoveredForDeletionIndex(rowIndex)
                      },
                      onDelete: (rowIndex: number) => {
                        setHoveredForDeletionIndex(-1)
                        if (accountsToCreate.length === 1) {
                          return
                        }

                        const accountsAfterDelete = removeIndex({
                          array: accountsToCreate,
                          index: rowIndex,
                        }).slice(0, -1)
                        setAccountsToCreate([
                          ...accountsAfterDelete,
                          emptyAccount,
                        ])
                      },
                    }
                  : undefined
              }
            />
          </TableWrapper>
        </Content>

        {hasAccountAlreadyCreated && (
          <>
            <Ct.Spacer width={2} />
            <LegendeWrapper>
              <Warning />
              <Ct.Spacer width={1} />
              <Text
                text={intl.formatMessage({
                  id: `accounting-plan.accounts.modal.input.number.${
                    multiple ? "multiple" : "single"
                  }.error`,
                })}
                textStyle={{
                  color: "amaranth",
                }}
              />
            </LegendeWrapper>
          </>
        )}

        {hasDuplicates && (
          <>
            <Ct.Spacer width={1} />
            <LegendeWrapper>
              <StyledWarning color={colors.orange} />
              <Ct.Spacer width={1} />
              <Text
                text={intl.formatMessage({
                  id: `accounting-plan.accounts.modal.input.number.duplicate.error`,
                })}
                textStyle={{
                  color: "orange",
                }}
              />
            </LegendeWrapper>
          </>
        )}

        <Ct.Spacer height={3} />

        <ButtonsPanel>
          <Ct.Button
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.button.back",
            })}
            colorType="Tertiary"
            width={42}
            onClick={() => {
              closeModal()
            }}
            colorScheme={{
              border: "mist",
              color: "cornflower",
              background: "mist",
            }}
          />
          <Ct.Button
            label={intl.formatMessage({
              id: "accounting-plan.accounts.modal.button.save",
            })}
            colorType="Primary"
            width={42}
            onClick={() => {
              handleFormSubmit()
            }}
            loadingStatus={
              accountCreateStatus === "LOADING" ? "loading" : "idle"
            }
            disabled={disableSaveButton()}
            colorScheme={{
              border: "mist",
              color: "white",
              background: "mist",
            }}
          />
        </ButtonsPanel>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140rem;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`
const Information = styled.div`
  width: 120rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`
const LegendeWrapper = styled.div`
  width: 120rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledWarning = styled(Warning)<{ color: string }>`
  & path {
    fill: ${({ color }) => color};
  }
`
const StyledInfoTitle = styled((props) => <Ct.Title {...props} />)`
  text-align: left;
  font-size: 2rem;
  text-transform: uppercase;
`
const Content = styled.div`
  width: 120rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`
const ButtonsPanel = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100rem;
`
const BorderRight = styled.div`
  border-right: 1px solid ${colors.mist};
  width: 100%;
`

import * as Ct from "ldlj"
import {
  MerchantCode,
  UpdateManyMerchantsForCode,
} from "../../store/ducks/merchantCodes.ducks"
import { useIntl } from "react-intl"
import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useRNBSelector } from "../../store/rootReducer"
import { useEffect, useState } from "react"
import { getIdFromParams } from "../../utils/company"
import { capitalizeFirstLetter } from "../../utils/string"
import { MultiSelect } from "../Commons/MultiSelect"
import { colors } from "../../styles/design.config"
import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"
import { ReactComponent as Warning } from "../../assets/warning-orange.svg"

export type optionType = { value: string; label: string }

interface AddMerchantsToCodeProps extends Ct.ModalComponentsProps {
  merchantCode: MerchantCode
}

export const AddMerchantsToCodeModal = ({
  merchantCode,
  isDisplayed,
  onClose,
}: AddMerchantsToCodeProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const selectedCompanyId = getIdFromParams(useParams())("company_id") || 0

  const [merchantNames, setMerchantNames] = useState<string>("")
  const [toggleNames, setToggleNames] = useState<boolean>(false)
  const [options, setOptions] = useState<Ct.Option<string>[]>()
  const [selection, setSelection] = useState<ReadonlyArray<optionType> | null>(
    []
  )
  const [displayErrorCentralize, setDisplayErrorCentralize] =
    useState<boolean>(false)

  const merchantsOfCompany = useRNBSelector(
    (state) => state.merchantCodes.merchantsOfCompany
  )

  const close = () => {
    if (selection && selection.length > 0) {
      const warningMessage = intl.formatMessage(
        { id: "accounting-plan.add-merchant.exit-message-confirm" },
        { code: merchantCode.code }
      )
      if (window.confirm(warningMessage)) {
        onClose()
      }
    } else {
      onClose()
    }
  }

  useEffect(() => {
    if (merchantCode.merchants && merchantCode.merchants?.length > 0) {
      setMerchantNames(
        merchantCode.merchants
          .map((m) => capitalizeFirstLetter(m.merchant_name))
          .join(", ")
      )
    }
  }, [merchantCode.merchants])

  useEffect(() => {
    if (merchantsOfCompany.length > 0) {
      if (merchantCode.merchants && merchantCode.merchants.length > 0) {
        const filteredMerchants = merchantsOfCompany.filter(
          (m) => !merchantCode.merchants?.some((mc) => mc.merchant_id === m.id)
        )
        setOptions(
          filteredMerchants.map((m) => {
            return {
              value: String(m.id),
              label: m.code
                ? capitalizeFirstLetter(m.name) + (" / code: " + m.code)
                : capitalizeFirstLetter(m.name),
            }
          })
        )
      } else {
        setOptions(
          merchantsOfCompany.map((m) => {
            return {
              value: String(m.id),
              label: m.code
                ? capitalizeFirstLetter(m.name) + (" / code: " + m.code)
                : capitalizeFirstLetter(m.name),
            }
          })
        )
      }
    }
  }, [merchantsOfCompany])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top={`calc(30vh - 20rem)`}
    >
      <StyledCard width={"140rem"}>
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage(
              { id: "accounting-plan.add-merchant.title" },
              { code: merchantCode.code }
            )}
          />

          <ModalClose onClick={() => close()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <Wrapper>
          {displayErrorCentralize && (
            <Ct.Text
              text={intl.formatMessage({
                id: "accounting-plan.add-merchant.not-centralize-error",
              })}
              textStyle={{
                color: "amaranth",
                fontSize: 2,
                fontWeight: 700,
              }}
            />
          )}

          {merchantCode?.merchants && merchantCode?.merchants?.length > 0 && (
            <WrapperList>
              <Ct.Text
                text={intl.formatMessage({
                  id: "accounting-plan.add-merchant.list-merchants",
                })}
                textStyle={{
                  fontSize: 2,
                  color: "navy",
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
              />

              <Ct.Spacer height={3} />

              <StyledP>
                <Ct.Text
                  text={
                    toggleNames ? merchantNames : merchantNames.slice(0, 450)
                  }
                  textStyle={{
                    fontSize: 2,
                    color: "navy",
                  }}
                />
                {merchantNames.length > 450 && (
                  <span>
                    <SeeMore
                      text={intl.formatMessage(
                        { id: "accounting-plan.table-content.see-more" },
                        { moreOrLess: toggleNames ? "moins" : "plus" }
                      )}
                      textStyle={{
                        fontSize: 2,
                        color: "cornflower",
                      }}
                      onClick={() => {
                        setToggleNames(!toggleNames)
                      }}
                    />
                  </span>
                )}
              </StyledP>
            </WrapperList>
          )}

          <Ct.Spacer height={4} />

          {options && (
            <StyledMultiSelectContainer>
              <MultiSelect
                intl={intl}
                field={{}}
                options={options}
                value={selection}
                label={"details"}
                domain={`accounting-plan.add-merchant`}
                optionType={"drop-down-label"}
                onChange={(newValue) => {
                  if (!merchantCode.centralize && selection?.length === 1) {
                    setDisplayErrorCentralize(true)
                    return
                  }
                  setSelection(newValue as optionType[])
                }}
                canToggleAllOptions={true}
                allOptionDisabled={!merchantCode.centralize}
              />
            </StyledMultiSelectContainer>
          )}

          {options && options.length === selection?.length && (
            <>
              <Ct.Spacer />
              <WrapperWarning>
                <Warning />
                <Ct.Spacer height={0} width={1} />
                <Ct.Text
                  text={intl.formatMessage({
                    id: "accounting-plan.create-code.warning-all-selected",
                  })}
                  textStyle={{
                    color: "orange",
                    fontSize: 1.75,
                    fontWeight: 700,
                  }}
                />
              </WrapperWarning>
            </>
          )}
        </Wrapper>

        <Wrapper>
          <Ct.Spacer height={4} />

          <WrapperButtons>
            <Ct.Button
              label="Retour"
              width={42.5}
              colorType={"Tertiary"}
              colorScheme={{
                background: "mist",
                color: "cornflower",
                border: "mist",
              }}
              onClick={close}
            />
            <Ct.Spacer width={4} />
            <Ct.Button
              label={"Sauvegarder"}
              width={42.5}
              disabled={selection?.length === 0}
              onClick={() => {
                if (selection && selection?.length > 0) {
                  const values = selection?.map((s) => Number(s.value))
                  dispatch(
                    UpdateManyMerchantsForCode(
                      selectedCompanyId,
                      merchantCode.code,
                      merchantCode.id,
                      values
                    )
                  )
                  onClose()
                }
              }}
            />
          </WrapperButtons>
        </Wrapper>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 4rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130rem;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`
const Wrapper = styled.div`
  width: 70%;
`
const StyledMultiSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const SeeMore = styled(Ct.Text)`
  text-decoration: underline;
  cursor: pointer;
  padding-left: 1rem;

  :hover {
    color: ${colors.cornflower};
  }
`
const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const StyledP = styled.p`
  max-height: 40rem;
  overflow-y: auto;
`
const WrapperWarning = styled.div`
  display: flex;
  align-items: center;
`

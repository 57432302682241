import * as Ct from "ldlj"
import { useIntl } from "react-intl"
import { Text } from "../Commons/Text"
import { Select, Option } from "../Commons/Select"
import { Input } from "../Commons/Input"
import { useEffect, useState } from "react"
import { RulesBillOfExchange } from "../../store/ducks/accounts.ducks"
import { useDispatch } from "react-redux"
import {
  CreateRuleBillOfExchangeThunk,
  EditRuleBillOfExchangeThunk,
  GetAllMerchantCodesThunk,
} from "../../store/ducks/merchantCodes.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { Button } from "../Commons/Button"
import { sizes } from "../../styles/design.config"
import styled from "styled-components/macro"
import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"
/* eslint-disable camelcase */

interface CreateRuleBillOfExchangeProps extends Ct.ModalComponentsProps {
  createOrEdit: "create" | "edit"
  currentRulesLength: number
  companyId: number
  rule: RulesBillOfExchange | null
}

export const CreateRuleBillOfExchangeModal = ({
  isDisplayed,
  onClose,
  createOrEdit,
  currentRulesLength,
  companyId,
  rule,
}: CreateRuleBillOfExchangeProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const merchantCodes = useRNBSelector(
    (state) => state.merchantCodes.merchantCodes
  )

  const priorityOptions: Array<Option<string>> = Array.from(
    { length: currentRulesLength + 1 },
    (_, index) => ({ value: String(index + 1), label: String(index + 1) })
  )
  const [priority, setPriority] = useState<Option<string> | null>(
    priorityOptions[0]
  )
  const [textInDescription, setTextInDescription] = useState<string>("")
  const [merchantCode, setMerchantCode] = useState<Option<string> | null>(null)

  const codeOptions: Array<Option<string>> = merchantCodes.map((c) => {
    return { value: String(c.id), label: c.code }
  })

  const disabledSave =
    (!rule &&
      (textInDescription === "" ||
        merchantCode === null ||
        (merchantCode && merchantCode.value === ""))) ||
    (rule
      ? !(
          rule?.text_in_description !== textInDescription ||
          rule?.priority_number !== Number(priority?.value) ||
          rule?.merchant_code_id !== Number(merchantCode?.value)
        )
      : false)

  useEffect(() => {
    dispatch(GetAllMerchantCodesThunk(companyId))
  }, [companyId, dispatch])

  useEffect(() => {
    if (createOrEdit === "edit" && rule) {
      setTextInDescription(rule.text_in_description)
      const code = codeOptions.find(
        (c) => Number(c.value) === rule.merchant_code_id
      )
      if (code) {
        setMerchantCode(code)
      }
      const priorityRule = priorityOptions.find(
        (p) => Number(p.value) === rule.priority_number
      )
      if (priorityRule) {
        setPriority(priorityRule)
      }
    }
  }, [rule, createOrEdit, merchantCodes])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={() => {
        onClose()
      }}
      left="50%"
      right="50%"
      top={`calc(30vh - 20rem)`}
    >
      <StyledCard width={"130rem"}>
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage({
              id:
                createOrEdit === "create"
                  ? `accounting-plan.lcr.create-or-edit-modal.title.create`
                  : "accounting-plan.lcr.create-or-edit-modal.title.edit",
            })}
          />

          <ModalClose onClick={() => onClose()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <Wrapper>
          <Text
            text={intl.formatMessage({
              id: `accounting-plan.lcr.create-or-edit-modal.trigger`,
            })}
            textStyle={{
              fontSize: 2,
              fontWeight: 600,
              textTransform: "uppercase",
              fontFamily: "Poppins",
            }}
          />

          <Ct.Spacer />

          <Triggers>
            <Select
              intl={intl}
              options={priorityOptions}
              value={priority}
              onChangeCallback={(e: Ct.Option<string>) => {
                setPriority(e)
              }}
              label={""}
              domain={"bank-management.rules.add-rule"}
              optionType={"priority"}
              defaultValue={priorityOptions[priorityOptions.length - 1]}
            />

            <Ct.Spacer width={4} />

            <Input
              label={intl.formatMessage({
                id: "bank-management.rules.add-rule.text-in-description",
              })}
              value={textInDescription}
              onChange={(e) => {
                setTextInDescription(e.target.value)
              }}
            />
          </Triggers>
          <Ct.Spacer height={5} />

          <Text
            text={intl.formatMessage({
              id: `accounting-plan.lcr.create-or-edit-modal.assignment`,
            })}
            textStyle={{
              fontSize: 2,
              fontWeight: 600,
              textTransform: "uppercase",
              fontFamily: "Poppins",
            }}
          />
          <Ct.Spacer />

          <div>
            <Select
              intl={intl}
              options={codeOptions}
              value={merchantCode}
              onChangeCallback={(e: Ct.Option<string>) => {
                setMerchantCode(e)
              }}
              label={""}
              domain={"accounting-plan.lcr.create-or-edit-modal"}
              optionType={"assignment"}
            />
          </div>

          <Ct.Spacer height={5} />

          <Ct.JustifyCenter>
            <Button
              label={intl.formatMessage({
                id:
                  createOrEdit === "create"
                    ? `accounting-plan.lcr.create-or-edit-modal.save`
                    : `accounting-plan.lcr.create-or-edit-modal.edit`,
              })}
              width={sizes.button.standard}
              disabled={disabledSave}
              onClick={() => {
                if (createOrEdit === "create") {
                  dispatch(
                    CreateRuleBillOfExchangeThunk(companyId, {
                      priority_number: Number(priority?.value),
                      merchant_code_id: Number(merchantCode?.value),
                      text_in_description: textInDescription,
                    })
                  )
                } else if (
                  createOrEdit === "edit" &&
                  rule &&
                  priority &&
                  merchantCode
                ) {
                  const editedRule = {
                    priority_number: Number(priority?.value),
                    merchant_code_id: Number(merchantCode?.value),
                    text_in_description: textInDescription,
                  }
                  dispatch(
                    EditRuleBillOfExchangeThunk(companyId, rule.id, editedRule)
                  )
                }

                onClose()
              }}
            />
          </Ct.JustifyCenter>
        </Wrapper>
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120rem;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`

const Wrapper = styled.div`
  align-items: flex-start;
  width: 90%;
`
const Triggers = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`

import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import * as Ct from "ldlj"
import styled from "styled-components/macro"
import {
  Instructions,
  LemInstructions,
  Merchant,
} from "../../store/ducks/merchants.ducks"
import { useRNBSelector } from "../../store/rootReducer"
import { getBuyOrSellFromParams, getIdFromParams } from "../../utils/company"
import { loadPNLAccountsThunk } from "../../store/ducks/accounts.ducks"
import {
  ChangeCardContent,
  ChangeHeader,
  ChangeItem,
  ChangesList,
  Mailto,
  TextNoChanges,
} from "../ChangesList"
import {
  getHistoryValues,
  sortMerchantChangesByDate,
} from "../../utils/merchants"
import { DateTime } from "luxon"
import { ReactComponent as CloseCross } from "../../assets/close-cross.svg"
import { sizes } from "../../styles/design.config"

interface MerchantHistoryModalProps extends Ct.ModalComponentsProps {
  merchantName: string
  merchant: Merchant
}

/* eslint-disable camelcase */

export const MerchantHistoryModal = ({
  isDisplayed,
  onClose,
  merchantName,
  merchant,
}: MerchantHistoryModalProps) => {
  const intl = useIntl()
  const params = useParams()
  const dispatch = useDispatch()
  const buyOrSell = getBuyOrSellFromParams(params)
  const companyId = getIdFromParams(params)("company_id") || 0

  const [allInstructions, setAllInstructions] = useState<
    Array<Instructions | LemInstructions>
  >([])

  const merchantCodes = useRNBSelector(
    (state) => state.merchantCodes.merchantCodes
  )
  const availableAccounts = useRNBSelector((state) =>
    buyOrSell ? state.accounts.PNL[buyOrSell] : []
  )
  const VATAccounts = useRNBSelector((state) =>
    buyOrSell === "buy"
      ? state.companySettings.possibleBuyAccounts
      : state.companySettings.possibleSellAccounts
  )
  const {
    user_merchant_code_instructions,
    user_merchant_instructions,
    user_legal_entity_instructions,
  } = useRNBSelector((state) => state.merchants.merchants[merchant.id])

  const close = () => {
    onClose()
  }

  useEffect(() => {
    dispatch(loadPNLAccountsThunk(companyId))
  }, [dispatch, companyId])

  useEffect(() => {
    if (user_merchant_instructions && user_merchant_code_instructions) {
      const filteredCodeInstruction = user_merchant_code_instructions.filter(
        (c) => c.metadata.code
      )

      setAllInstructions([
        ...(user_legal_entity_instructions?.slice(0, 10) || []),
        ...user_merchant_instructions.reverse().slice(0, 10),
        ...filteredCodeInstruction.slice(0, 10),
      ])
    }
  }, [user_merchant_code_instructions, user_merchant_instructions])

  return (
    <Ct.Modal
      isDisplayed={isDisplayed}
      onClose={close}
      left="50%"
      right="50%"
      top={`calc(30vh - 20rem)`}
    >
      <StyledCard width={"140rem"}>
        <StyledHeader>
          <div />

          <StyledTitle
            text={intl.formatMessage({
              id: `ged.client.modal.title`,
            })}
          />

          <ModalClose onClick={() => close()}>
            <CloseCross />
          </ModalClose>
        </StyledHeader>

        <Ct.Spacer height={3} />
        <Ct.Separator size="full" />
        <Ct.Spacer height={3} />

        <ChangeCardContent>
          <ChangeHeader>
            <Ct.Text
              text={intl.formatMessage({
                id: `ged.client.modal.${buyOrSell}`,
              })}
              textStyle={{
                textTransform: "uppercase",
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={" : "}
              textStyle={{
                fontWeight: 700,
              }}
            />
            <Ct.Text
              text={merchantName}
              textStyle={{ fontWeight: 700, textTransform: "uppercase" }}
            />
          </ChangeHeader>

          {allInstructions.length > 0 ? (
            <ChangesList>
              {sortMerchantChangesByDate(allInstructions).map((merchant) => (
                <ChangeItem key={merchant.created_at}>
                  <Ct.Text
                    text={intl.formatMessage(
                      {
                        id: `ged.client.modal.${merchant.instruction_type}`,
                      },
                      {
                        buy_or_sell: buyOrSell === "buy" ? "charge" : "produit",
                      }
                    )}
                  />

                  <Mailto href={`mailto:${merchant.user}`}>
                    {merchant.user}
                  </Mailto>

                  <Ct.Text
                    text={
                      " (" +
                      DateTime.fromJSDate(
                        new Date(merchant.created_at)
                      ).toFormat("dd/MM/yyyy HH:mm:ss") +
                      ")."
                    }
                  />
                  <Ct.Text
                    text={getHistoryValues(
                      merchant,
                      merchantCodes,
                      availableAccounts,
                      VATAccounts,
                      buyOrSell || "buy"
                    )}
                    textStyle={{
                      fontWeight: 700,
                    }}
                  />
                </ChangeItem>
              ))}
            </ChangesList>
          ) : (
            <>
              <Ct.Spacer height={2} />
              <TextNoChanges
                text={intl.formatMessage({ id: `ged.client.modal.no-changes` })}
              />
              <Ct.Text
                text={intl.formatMessage({
                  id: `ged.client.modal.${buyOrSell}`,
                })}
                textStyle={{
                  textTransform: "lowercase",
                }}
              />
              .
              <Ct.Spacer height={2} />
            </>
          )}
        </ChangeCardContent>

        <Ct.Spacer height={4} />

        <Ct.Button
          label={intl.formatMessage({ id: "ged.client.modal.ok" })}
          onClick={close}
          width={sizes.button.standard}
        />
      </StyledCard>
    </Ct.Modal>
  )
}

const StyledCard = styled((props) => <Ct.Card {...props} />)`
  padding: 3.5rem 0;
  border-radius: 2rem;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130rem;
`
const StyledTitle = styled((props) => <Ct.Title {...props} />)`
  font-size: 24px;
`
const ModalClose = styled.div`
  cursor: pointer;
`
